<template>
  <div class="all-segments">
    <br/>
    <h1 class="_pl-15">Список всех сегментов</h1>
    <p class="_pl-15">Обновление данных происходит в 9:00 и 18:00 по Москве</p>
    <table-container
      :id="3"
      @updTable="updTable"
			@updSegmentType="updSegmentType"
      @updSegmentID="updSegmentID"
      :showProblematicFilter="true"
      :showSegmentFilter="true"
      :showSegmentSearchFilter="true"
      :segmentSearchList="problematicSegmentsList"
    >
      <template v-slot:total>
        <p v-if="problematicTotal">
          <strong>Найдено сегментов: </strong> {{problematicTotal}}
        </p>
      </template>
      <v-data-table
        :headers="headers"
        :items="segments"
        :loading="loading"
        dense
        :footer-props="{
          'items-per-page-options': [100, 500, 1000],
          'items-per-page-text': 'Строк на странице:'
        }"
        @update:options="updPage"
        :server-items-length="problematicTotal"
      >
        <template v-slot:header.query_count_diff="{}">
          <div class="header-btn" @click="sortBy('query_count_prc')">
            Запросов Diff
          <v-icon v-if="entity.type === 'query_count_prc' && entity.isReverse" small>mdi-arrow-up</v-icon>
          <v-icon v-else small>mdi-arrow-down</v-icon>
          </div>
        </template>
        <template v-slot:header.url_count_diff="{}">
          <div class="header-btn" @click="sortBy('url_count_prc')">
            Документов Diff
            <v-icon v-if="entity.type === 'url_count_prc' && entity.isReverse" small>mdi-arrow-up</v-icon>
            <v-icon v-else small>mdi-arrow-down</v-icon>
          </div>
        </template>

        <template v-slot:item.name="{ item }">
          <div v-if="item.name">
            {{item.name}}
          </div>
          <div v-else>
            -
          </div>
        </template>
        <template v-slot:item.segment_id="{ item }">
          {{item.segment_id}}
          <div class="controls">
            <v-tooltip top>
              <template  v-slot:activator="{ on, attrs }">
                <a
                  class="control-btn"
                  :href="generateLink(item, false)"
                  target="_blank"
                >
                  <v-icon small color="gray" v-bind="attrs" v-on="on">mdi-link</v-icon>
                </a>
              </template>
              <span>Main</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <a
                  class="control-btn"
                  :href="generateLink(item, true)"
                  target="_blank"
                >
                  <v-icon small color="blue" v-bind="attrs" v-on="on">mdi-link</v-icon>
                </a>
              </template>
              <span>CH</span>
            </v-tooltip>
          </div>
        </template>
        <template v-slot:item.ch_exist="{ item }">
          <div v-if="item.entity_count && item.entity_count.chstat.segment_count">
            <span>{{item.entity_count.chstat.segment_count}}</span>
            <v-icon class="check-icon" small color="blue">mdi-check</v-icon>
          </div>
          <div v-else>
            <span>0</span>
            <v-icon class="check-icon" small color="red">mdi-exclamation</v-icon>
          </div>
        </template>
        <template v-slot:item.type="{ item }">
          <div v-if="item.type">
            {{item.type === 'query_group' ? 'Группы запросов' : item.type === 'group' ? 'Группы страниц' : item.type === 'category' ? 'Категории' : item.type}}
          </div>
          <div v-else>-</div>
        </template>
        <template v-slot:item.query_count_main="{ item }">
          <div class="d-flex justify-space-between elements">
            <a
              v-if="item.entity_count && item.entity_count.main['query_count']"
              :href="generateQueriesLink(item, false)"
              target="_blank"
            >
              {{item.entity_count.main['query_count']}}
            </a>
            <a
              v-else
              :href="generateQueriesLink(item, false)"
              target="_blank"
            >
              0
            </a>
          </div>
        </template>
        <template v-slot:item.query_count_ch="{ item }">
          <div class="d-flex justify-space-between elements">
            <a
              v-if="item.entity_count && item.entity_count.chstat['query_count']"
              :href="generateQueriesLink(item, true)"
              target="_blank"
            >
              {{item.entity_count.chstat['query_count']}}
            </a>
            <a
              v-else
              :href="generateQueriesLink(item, true)"
              target="_blank"
            >
              0
            </a>
          </div>
        </template>
        <template v-slot:item.query_count_diff="{ item }">
          <b :class="[item.entity_count.diff['query_count_prc'] !== 0 && 'danger']">
            {{ item.entity_count.diff['query_count_prc'] }}%
          </b>
          <span>({{item.entity_count.diff['query_count']}})</span>
        </template>
        <template v-slot:item.url_count_main="{ item }">
          <div class="d-flex justify-space-between elements">
            <a
              v-if="item.entity_count && item.entity_count.main['url_count']"
              :href="generateUrlsLink(item, false)"
              target="_blank"
            >
              {{item.entity_count.main['url_count']}}
            </a>
            <a
              v-else
              :href="generateUrlsLink(item, false)"
              target="_blank"
            >
              0
            </a>
          </div>
        </template>
        <template v-slot:item.url_count_ch="{ item }">
          <div class="d-flex justify-space-between elements">
            <a
              v-if="item.entity_count && item.entity_count.chstat['url_count']"
              :href="generateUrlsLink(item, true)"
              target="_blank"
            >
              {{item.entity_count.chstat['url_count']}}
            </a>
            <a
              v-else
              :href="generateUrlsLink(item, true)"
              target="_blank"
            >
              0
            </a>
          </div>
        </template>
        <template v-slot:item.url_count_diff="{ item }">
          <b :class="[item.entity_count.diff['url_count_prc'] !== 0 && 'danger']">
            {{ item.entity_count.diff['url_count_prc'] }}%
          </b>
          <span>({{item.entity_count.diff['url_count']}})</span>
        </template>
      </v-data-table>
    </table-container>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import TableContainer from '@/components/data/segments/tableContainer.vue'

export default {
	components: { TableContainer },
	data () {
		return {
			loading: true,
			headers: [
				{
					text: 'Наименование проекта',
					value: 'project_name',
					sortable: false
				},
				{
					text: 'ID проекта',
					value: 'project_id',
					sortable: false
				},
				{
					text: 'Наименование сегмента',
					value: 'segment_name',
					sortable: false
				},
				{
					text: 'ID сегмента',
					value: 'segment_id',
					sortable: false
				},
				{
					text: 'Наличие в CH',
					value: 'ch_exist',
					sortable: false
				},
				{
					text: 'Тип сегмента',
					value: 'type',
					sortable: false
				},
				{
					text: 'Запросов Main',
					value: 'query_count_main',
					sortable: false
				},
				{
					text: 'Запросов CH',
					value: 'query_count_ch',
					sortable: false
				},
				{
					text: 'Запросов Diff',
					value: 'query_count_diff',
					sortable: false
				},
				{
					text: 'Документов Main',
					value: 'url_count_main',
					sortable: false
				},
				{
					text: 'Документов CH',
					value: 'url_count_ch',
					sortable: false
				},
				{
					text: 'Документов Diff',
					value: 'url_count_diff',
					sortable: false
				}
			],
			entity: {
				isReverse: false,
				type: 'default',
				segmentType: undefined,
				segment_id: undefined
			},
			tableFooter: {
				page: 1,
				itemsPerPage: 100
			}
		}
	},

	computed: {
		...mapState({
			problematicTotal: state => state.segments.problematicTotal,
			problematicSegments: state => state.segments.problematicSegments
		}),
		...mapGetters({
			sortedProblematicSegments: 'segments/sortedProblematicSegments',
			problematicSegmentsList: 'segments/problematicSegmentsList'
		}),
		segments () {
			return this.sortedProblematicSegments(this.entity) ? this.sortedProblematicSegments(this.entity) : []
		}
	},

	watch: {
		tableFooter: {
			deep: true,
			async handler (val) {
				const query = this.$route.query
				const projectID = query.project_id
				const showProblematic = query.only_diff

				const obj = {
					project_ids: projectID
						? !Array.isArray(projectID)
							? [projectID]
							: projectID
						: undefined,
					active: 1,
					only_diff: showProblematic,
					offset: val.page === 1 ? 0 : val.itemsPerPage * (val.page - 1),
					limit: val.itemsPerPage
				}

				await this.fetchSegments(obj)
			}
		}
	},

	methods: {
		...mapActions({
			getProblematicSegments: 'segments/getProblematicSegments'
		}),

		async updTable (payload) {
			const query = {
				project_ids: payload.project
					? !Array.isArray(payload.project)
						? [payload.project]
						: payload.project
					: undefined,
				active: 1,
				only_diff: payload.isProblematic ? 1 : '0',
				limit: this.tableFooter.itemsPerPage
			}

			await this.fetchSegments(query)
		},

		async fetchSegments (query) {
			try {
				this.loading = true
				this.$store.commit('segments/addProblematicSegments', [])
				await this.getProblematicSegments({ ...query })
			} catch (error) {
				this.$notify({ type: 'error', title: error })
			} finally {
				this.loading = false
			}
		},

		generateLink (item, isCH) {
			if (item) {
				const baseLink = item.type === 'query_group'
					? `https://spa.seowork.ru/semantics/query-groups/${item.project_id}?query_group_id=${item.segment_id}${isCH ? '&ch=1' : ''}`
					: item.type === 'group'
						? `https://spa.seowork.ru/semantics/groups/${item.project_id}?group_id=${item.segment_id}${isCH ? '&ch=1' : ''}`
						: item.type === 'category'
							? `https://spa.seowork.ru/semantics/categories/${item.project_id}?category_id=${item.segment_id}${isCH ? '&ch=1' : ''}`
							: null
				return baseLink || null
			}
			return null
		},

		generateQueriesLink (item, isCH) {
			if (item) {
				switch (item.type) {
				case 'query_group':
					return `https://spa.seowork.ru/semantics/queries/${item.project_id}?&query_group_id=${item.segment_id}${isCH ? '&ch=1' : ''}`
				case 'group':
					return `https://spa.seowork.ru/semantics/queries/${item.project_id}?&group_id=${item.segment_id}${isCH ? '&ch=1' : ''}`
				case 'category':
					return `https://spa.seowork.ru/semantics/queries/${item.project_id}?&category_id=${item.segment_id}${isCH ? '&ch=1' : ''}`
				default:
					return null
				}
			}
			return null
		},
		generateUrlsLink (item, isCH) {
			if (item) {
				switch (item.type) {
				case 'query_group':
					return `https://spa.seowork.ru/semantics/urls/${item.project_id}?&query_group_id=${item.segment_id}${isCH ? '&ch=1' : ''}`
				case 'group':
					return `https://spa.seowork.ru/semantics/urls/${item.project_id}?&group_id=${item.segment_id}${isCH ? '&ch=1' : ''}`
				case 'category':
					return `https://spa.seowork.ru/semantics/urls/${item.project_id}?&category_id=${item.segment_id}${isCH ? '&ch=1' : ''}`
				default:
					return null
				}
			}
			return null
		},

		updSegmentType (payload) {
			if (payload.segmentType) {
				this.entity.segmentType = payload.segmentType
			} else {
				this.entity.segmentType = undefined
			}
		},
		updSegmentID (payload) {
			if (payload.segment_id) {
				this.entity.segment_id = payload.segment_id
			} else {
				this.entity.segment_id = undefined
			}
		},
		sortBy (type) {
			if (this.entity.type !== type) {
				this.entity.type = type
				this.entity.isReverse = false
			} else {
				this.entity.type = type
				this.entity.isReverse = !this.entity.isReverse
			}
		},
		updPage (pagination) {
			this.tableFooter.page = pagination.page
			this.tableFooter.itemsPerPage = pagination.itemsPerPage
		}
	}
}
</script>

<style lang="scss" scoped>

  ._pl-15 {
    padding-left: 15px;
  }
  .table-title {
    font-weight: 400;
    font-size: 28px;
  }

  .danger {
    color: red;
  }
  .all-segments {
    p {
        margin-bottom: 0;
      }
  }

  .controls {
    display: flex;
    margin-top: 5px;
    .control-btn {
      &:not(:last-child) {
        margin-right: 10px;
      }
    }

  }
  .elements {
    max-width: 75%;
  }

  .check-icon {
    margin-left: 10px;
  }

</style>
